.loginHeader {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 20px 120px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
}

.loginHeader .logo {
    width: 125px;
}

.loginHeader .logo img {
    width: 100%;
    display: block;
}

.login {
    background: url('../../assets/img/login_bg.jpg');
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    padding: 70px 15px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    background: rgba(217, 217, 217, 0.1);
    z-index: -1;
}


.login_box {
    background: #fff;
    width: 440px;
    border-radius: 6px;
    margin-top: 40px;
    padding: 70px 40px;
}

.loginTop {
    margin-bottom: 25px;
    text-align: center;
}

.loginTop h2 {
    margin: 0 0 5px;
    font-size: 32px;
    font-weight: 600;
    color: #000;
}

.loginTop p {
    font-size: 20px;
    font-weight: 500;
    color: #636363;
    margin: 0;
}

.loginField,
.loginforget {
    margin-bottom: 20px;
}

.loginforget .rememberCheckWrap {
    margin-left: 0;
}

.loginforget .rememberCheck {
    padding: 0 8px 0 0;
}

.loginforget .MuiFormControlLabel-label {
    font-size: 14px;
    color: #000;
}

.loginforget .rememberCheck svg {
    color: #000;
}

.forgotpass {
    font-size: 14px;
    color: #F07023;
}

.loginField .inputField .MuiInputBase-formControl {
    border-radius: 4px;
    background: #E9EBEC;
    height: 60px;
}

.loginField .inputField .MuiInputBase-formControl:before,
.loginField .inputField .MuiInputBase-formControl:hover:before {
    border-bottom: 0;
}

.loginField .inputField .MuiInputBase-formControl:after {
    border-bottom-color: #F07023;
    border-radius: 8px;
}

.loginField .inputField label {
    font-size: 14px;
    /* color: #636363; */
    transform: translate(18px, 19px) scale(1);
}

.loginField .inputField input {
    padding-left: 18px;
    padding-right: 18px;
    height: 100%;
    box-sizing: border-box;
    font-size: 14px;
    color: #3f3f3f;
}

.loginField .inputField label.MuiInputLabel-shrink {
    transform: translate(18px, 7px) scale(0.75);
}

.loginBottom .loginBtn {
    height: 60px;
    box-shadow: none;
    background: #F07023;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.loginBottom .loginBtn:hover {
    box-shadow: none;
    background: #d0621d;
}