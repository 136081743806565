.leftBar {
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    transition: all 300ms ease-in-out;
    border-right: 1px solid #F5F5F5;
}

.leftBar.leftBarClosed {
    width: 70px;
    border-right: 1px solid transparent;
}


/* Left Small Bar */
.leftbarSmall {
    width: 70px;
    border-right: 1px solid #F5F5F5;
    height: calc(100vh - 63px);
    overflow-y: auto;
    z-index: 1;
    position: relative;
    background: #fff;
}

.small_logo {
    width: 30px;
    padding: 20px 0;
    margin: 0 auto;
}

.small_logo img {
    display: block;
    width: 100%;
}

.small_navigation ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.small_navigation li {
    display: flex;
    width: 42px;
    height: 42px;
    margin: 0 auto 10px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in-out;
    cursor: pointer;
}

.small_navigation li:hover,
.small_navigation li.current {
    background: #FEF1E9;
}

.small_navigation li:hover svg,
.small_navigation li.current svg {
    color: #F07023;
}

.small_navigation li svg {
    width: 20px;
    height: auto;
    transition: all 300ms ease-in-out;
}


/* Left Big Bar */
.leftbarBig {
    position: fixed;
    top: 0;
    left: 70px;
    width: 180px;
    height: 100%;
    overflow-y: auto;
    transition: all 300ms ease-in-out;
}

.leftBar.leftBarClosed .leftbarBig {
    transform: translateX(-500px);
}

.leftbarBigTop {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 25px 0;
}

.leftbarSmallBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 70px;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #F5F5F5;
}

.leftbarSmallBottom button {
    background: transparent;
    border: none;
    display: flex;
    width: 42px;
    height: 42px;
    font-size: 20px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.leftbarSmallBottom button:hover {
    background: #FEF1E9;
    color: #F07023;
}

.leftbarBigItem {
    display: none;
}

.leftbarBigItem.current {
    display: block;
}

.leftbarBigItem h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 15px;
}

.leftbarBigItem h3 a {
    color: #F07023;
    text-decoration: none;
}

.leftbarBigItem ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.leftbarBigItem ul li {
    display: block;
}

.leftbarBigItem li a {
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    align-items: center;
    display: flex;
}

.leftbarBigItem li a img {
    width: 20px;
    margin-right: 15px;
}