.dashboardBody {
    background: #F3F3F9;
    min-height: 100vh;
}

.dashboardRight {
    padding-left: 250px;
    padding-top: 70px;
    transition: all 300ms ease-in-out;
}

.dashboardRight.dashboardRightClose {
    padding-left: 70px;
}

.dashboardRight h1 {
    margin: 0;
    background: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 25px;
    border-top: 1px solid #F5F5F5;
}

/* Table Search */
.tableSearchForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tableSearchForm_select {
    max-width: 32%;
    flex: 0 0 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.tableSearchForm_select select {
    width: 100%;
}

.selectBox {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    background-image: url('../../assets/img/angledown.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center right 15px;
    padding: 5px 15px;
    font-weight: 500;
    color: #676767;
}

.selectBox:focus {
    outline: none;
    box-shadow: none;
}

.inputBox {
    width: 100%;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    border: none;
    padding: 5px 15px;
    font-weight: 500;
    color: #676767;
}

.inputBox:focus {
    outline: none;
}

.tableSearchForm_button .orangeBtn {
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    box-shadow: none;
    border-radius: 4px;
    background: #F07023;
    border: none;
    color: #fff;
    text-transform: capitalize;
}

.tableSearchForm_button .orangeBtn:hover {
    box-shadow: none;
    background: #d9631a;
}

.dashboardInner {
    padding: 24px;
}

.dashboard_box {
    background: #fff;
    padding: 20px 15px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    margin-bottom: 20px;
}

.dashboard_box_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #F3F3F9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.dashboard_box_item {
    flex: 1 0 0%;
    padding-left: 20px;
}

.dashboard_box_item h3 {
    margin: 0 0 4px;
    font-size: 16px;
    color: #636363;
    font-weight: 600;
}

.dashboard_box_item span {
    font-size: 32px;
    font-weight: 800;
    color: #F07023;
    line-height: 0.9;
}

.search_vehicle {
    margin-bottom: 30px;
}

.search_vehicle:last-child {
    margin-bottom: 0;
}

.search_vehicle h4 {
    margin: 0 0 20px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.tableSearchBar {
    margin-bottom: 20px;
}

.tableWithHeader {
    background: #fff;
}

.tableCustomHeader {
    padding: 10px 16px;
}

.vehicleList_heading {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    align-items: center;
}

.vehicleList_heading i {
    font-size: 25px;
    margin-right: 15px;
}

.addBtnwrap span {
    font-size: 12px;
    display: inline-block;
    margin-right: 15px;
}

.addBtnwrap .orangeBtn {
    font-size: 12px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 4px;
    background: #F07023;
    border: none;
    color: #fff;
    text-transform: capitalize;
}

.addBtnwrap .orangeBtn:hover {
    box-shadow: none;
    background: #d9631a;
}

.protextHideWrap .protextHide .MuiDataGrid-columnHeaders+div+div {
    display: none;
}

.protextHideWrap .protextHide {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
}

.protextHideWrap .protextHide {
    font-size: 13px;
}