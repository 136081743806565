@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

button {
    cursor: pointer;
}

.trasition300 {
    transition: all 300ms ease-in-out;
}

a {
    text-decoration: none;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.col-auto {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 auto;
    max-width: auto;
}

.col {
    flex: 1 0 0%;
    padding-left: 10px;
    padding-right: 10px;
}

.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.d-none {
    display: none;
}


/* Table Btns */
.tableBtn .actionBtn {
    padding: 0;
    min-width: auto;
    width: 25px;
    height: 25px;
    background: transparent;
    border: none;
    margin: 0 2px;
    font-size: 17px;
    line-height: 1;
}

.tableBtn .viewBtn {
    color: #F07023;
}

.tableBtn .editBtn {
    color: #00C0EA;
}

.tableBtn .deleteBtn {
    color: #FF0000;
}

.tableBtn .exitBtn img {
    width: 20px;
    margin: 0 auto;
}