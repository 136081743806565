.mainHeader {
    padding: 20px 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    width: calc(100% - 250px);
    background: #fff;
    transition: all 300ms ease-in-out;
}

.mainHeaderSlide {
    width: calc(100% - 70px);
}

.slideBtn {
    background: transparent;
    border: none;
    padding: 8px 5px;
    margin-left: 20px;
}

.slideBtn span {
    display: block;
    height: 2px;
    border-radius: 3px;
    background: #F07023;
    width: 20px;
    margin-bottom: 4px;
    transition: all 300ms ease-in-out;
}

.slideBtn span:last-child {
    margin-bottom: 0;
    width: 10px;
}

.slideBtn span:first-child {
    width: 14px;
}

.slideBtn.slideBtnOpen span:first-child {
    width: 10px;
    -webkit-transform: rotate(45deg) translateX(10px) translateY(-6px);
    transform: rotate(45deg) translateX(10px) translateY(-6px);
}

.slideBtn.slideBtnOpen span:last-child {
    -webkit-transform: rotate(-45deg) translateX(10px) translateY(6px);
    transform: rotate(-45deg) translateX(10px) translateY(6px);
}

.slideBtn.slideBtnOpen span:nth-child(2) {
    transform: rotate(180deg);
}